import { CoverType } from "../../elements/CoverButton"
import theme from "../../theme"
export const PRODUCT_COLOR = theme.palette.custom.blue
export const COVER_LINK = CoverType.ALL

export { default as BoardOfDirectors } from "./BoardOfDirectors"
export { default as CompanyValues } from "./CompanyValues"
export { default as FinancialServices } from "./FinancialServices"
export { default as SoWhyWorkersLife } from "./SoWhyWorkerslife"
export { default as Introduction } from "./Introduction"
export { default as TailorMade } from "./TailorMade"
export { default as Conclusion } from "./Conclusion"
