import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import {
  CompanyValues,
  Conclusion,
  FinancialServices,
  Introduction,
  SoWhyWorkersLife,
  TailorMade,
} from "../sections/why-workerslife"
import { ArticleJsonLd } from "gatsby-plugin-next-seo"

const DESCRIPTION =
  "Secure your family's financial future with Workerslife's life and funeral insurance products. Comprehensive coverage for South African workforce."
const IMAGE =
  "https://www.workerslife.co.za/static/7df46c6ae0f32c901bfb2faa7a1c1802/44474/header.webp"

const WhyWorkerslife: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Workerslife Insurance: Life, Funeral & More"
        description={DESCRIPTION}
        noSuffix
        image={IMAGE}
      />
      <Introduction />
      <ArticleJsonLd
        authorName="Workerslife"
        authorType="Organization"
        headline="So why Workerslife"
        description={DESCRIPTION}
        url="https://www.workerslife.co.za/why-workerslife"
        images={[IMAGE]}
        publisherName=""
        publisherLogo=""
        datePublished=""
      />
      <SoWhyWorkersLife />
      <TailorMade />
      <CompanyValues />
      <FinancialServices />
      <Conclusion />
    </PageWrapper>
  )
}

export default WhyWorkerslife

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
