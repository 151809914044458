import React from "react"
import styled from "styled-components"

import { COVER_LINK, PRODUCT_COLOR } from "."
import { CoverButton } from "../../elements"

const Conclusion = (): JSX.Element => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>Join the Workerslife Family</h2>
        <p>At Workerslife, our commitment is unwavering.</p>
      </div>

      <p>
        We are here to protect and inspire all South Africans. Our insurance
        products are designed to provide coverage options that ensure financial
        security and offer comprehensive insurance benefits. We believe in
        transparency and providing our customers with detailed policy details,
        so they can make informed decisions about their financial future. Join
        the Workerslife family and secure your future with a partner you can
        trust.
      </p>
      <div className="buttonContainer">
        <CoverButton
          coverType={COVER_LINK}
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Explore Our Insurance Products"
        />
      </div>
      <p className="styledFooter">
        Make Workerslife your partner in securing your financial future. Explore
        our range of insurance products, including life insurance, funeral
        insurance, short-term insurance, and legal insurance. We are here to
        protect and inspire.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  p {
    padding: 0 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  .coverButton {
    margin: 60px auto;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;
    padding: 0 30px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    p,
    .sectionHeader {
      padding: 0 60px;
    }
  }
`
