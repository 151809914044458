import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Article } from "../../components"

const SoWhyWorkersLife: React.FC = () => {
  return (
    <Container>
      <Article
        title="Our Commitment to South Africans"
        image={
          <StaticImage
            src="../../assets/images/why-workerslife/values-1.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>
          At Workerslife, our commitment is to all South Africans. We are a 100%
          black-owned and operated holding company for leading financial
          services providers, including both life and non-life insurers. Our
          mission is guided by the philosophy of Ubuntu, &quot;we are because
          you are.&quot; We believe in the interconnectedness of all South
          Africans and that by working together, we can achieve financial
          security and peace of mind.
        </p>
      </Article>
    </Container>
  )
}

export default SoWhyWorkersLife

const Container = styled.div`
  padding: 0 20px;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;
  }
`
