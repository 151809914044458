import React from "react"
import styled from "styled-components"
import { SectionHeader } from "../../components"
import { CoverButton } from "../../elements"
import { COVER_LINK, PRODUCT_COLOR } from "."

const Introduction: React.FC = () => {
  return (
    <Container>
      <SectionHeader
        buttonColor={PRODUCT_COLOR}
        coverType="products"
        title="Discover Why Workerslife is the Right Choice for Your Insurance Needs"
        subTitle="At Workerslife, we are committed to safeguarding the financial future of all South Africans. Learn why our insurance products, including life insurance, funeral insurance, short-term insurance, and legal insurance, are designed to provide financial security and peace of mind. Join us in our mission to protect and inspire."
      />
      <div className="buttonContainer">
        <CoverButton
          coverType={COVER_LINK}
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Explore Our Insurance Products"
        />
      </div>
      <p>
        Choosing the right insurance provider is a critical decision. When it
        comes to protecting your financial future and that of your loved ones,
        it&apos;s essential to select a company that shares your values and
        understands your unique needs. Workerslife is an insurance company that
        was born from the dedication of the nation&apos;s workers. Since its
        inception in 1996, Workerslife has been committed to defending the
        rights of the workforce and providing them with the security of knowing
        they have access to insurance that can help protect and safeguard a
        bright future for their families and dependents.
      </p>
    </Container>
  )
}

export default Introduction

const Container = styled.div`
  margin: 30px auto;
  p {
    padding: 0 20px;
  }
  .buttonContainer {
    display: flex;
    margin: 0 auto 60px auto;
    justify-content: center;
    padding: 0 20px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 60px auto;
    p {
      padding: 0 58px;
    }
  }
`
