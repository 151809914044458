import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Article } from "../../components"

const TailorMade: React.FC = () => {
  return (
    <Container>
      <Article
        title="Tailor-Made Insurance Products"
        image={
          <StaticImage
            src="../../assets/images/why-workerslife/values-3.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
        imageLeft
      >
        <p>
          Workerslife understands the unique needs of the South African
          workforce. We provide quality life cover and investment products that
          are tailor-made around the needs of our customers. Our insurance
          products are designed to address the diverse financial challenges that
          South Africans may face. Whether it&apos;s life insurance, funeral
          insurance, short-term insurance, or legal insurance, Workerslife has
          you covered.
        </p>
      </Article>
    </Container>
  )
}

export default TailorMade

const Container = styled.div`
  padding: 0 20px;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;
  }
`
