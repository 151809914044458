import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import { ColorTiles } from "../../components"

type Belief = {
  color: string
  header: string
  body: string
}

export const BELIEFS: Belief[] = [
  {
    color: "#A51B23",
    header: "Workerslife Assurance Company Limited",
    body: "A licensed insurer conducting life insurance business and a Financial Services Provider with FSP number: 392.",
  },
  {
    color: "#F0A949",
    header: "Workerslife Insurance Limited",
    body: "A licensed insurer conducting non-life insurance business and a Financial Services Provider with FSP number: 4353.",
  },
  {
    color: "#00427C",
    header: "Workerslife Direct (Sales and Distribution)",
    body: "An authorized Financial Services Provider with FSP number 1687, providing intermediary services (sales and distribution) for Workerslife Assurance Company Limited and Workerslife Insurance Limited companies.",
  },
]

const query = graphql`
  {
    bgImage: file(
      relativePath: { eq: "why-workerslife/we-believe-in-img.png" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const FinancialServices = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <Container>
      <ColorTiles
        title="Workerslife Group comprises three key financial services entities:"
        backgroundImage={imageData}
        tiles={BELIEFS.map(belief => ({
          tileType: "text-only",
          ...belief,
        }))}
      />
    </Container>
  )
}

export default FinancialServices

const Container = styled.div`
  p {
    line-height: 36px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    span {
      font-size: 30px;
    }
  }
`
