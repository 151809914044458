import React from "react"
import styled from "styled-components"

import { DirectorList } from "../../components"
import {
  ASSURANCE_DIRECTORS,
  INSURANCE_DIRECTORS,
  DIRECT_DIRECTORS,
  GROUP_DIRECTORS,
} from "../../constants/directors"

import theme from "../../theme"

const PALETTE = theme.palette.custom

const BoardOfDirectors: React.FC = () => (
  <Container>
    <DirectorList
      color={PALETTE.green}
      company="Workers Life Group (Pty) Ltd"
      content={GROUP_DIRECTORS}
    />
    <DirectorList
      color={PALETTE.red}
      company="Workerslife Assurance Company Limited"
      content={ASSURANCE_DIRECTORS}
    />
    <DirectorList
      color={PALETTE.yellow}
      company="Workerslife Insurance"
      content={INSURANCE_DIRECTORS}
    />
    <DirectorList
      color={PALETTE.blue}
      company="Workerslife Direct"
      content={DIRECT_DIRECTORS}
    />
  </Container>
)

export default BoardOfDirectors

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  row-gap: 100px;

  @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0 65px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    row-gap: 225px;
  }
`
