import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../components"

const CompanyValues: React.FC = () => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Our Core Values"
      color="#00427C"
      className="slider"
      content={[
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-1.png"
              {...ImageProperties}
            />
          ),
          topic: "Innovative",
          label:
            "We are committed to advancing through technology and entrepreneurship, ensuring that our products and services remain cutting-edge and accessible.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-2.png"
              {...ImageProperties}
            />
          ),
          topic: "Trustworthiness",
          label:
            "Trust is the cornerstone of our relationships with our customers. We are ethical, consistent, and have integrity. We keep our promises, ensuring that our customers can rely on us.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-3.png"
              {...ImageProperties}
            />
          ),
          topic: "Inclusivity",
          label:
            "We celebrate diversity and foster a professional work environment that is friendly and empathetic. Our team is diverse, and we take pride in creating an environment where everyone feels valued.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-4.png"
              {...ImageProperties}
            />
          ),
          topic: "Excellence",
          label:
            "We are committed to delivering exceptional results in terms of profits, efficiencies, market share, and shareholder value. We measure our success by the success of our customers.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-5.png"
              {...ImageProperties}
            />
          ),
          topic: "Synergy",
          label:
            "Building strong relationships with our stakeholders is essential. This includes our clients, shareholders, regulators, and employees. Together, we can achieve more.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/why-workerslife/values-6.png"
              {...ImageProperties}
            />
          ),
          topic: "Empowerment",
          label:
            "We aim to create understanding and ability in our team and stakeholders. By empowering our employees and customers with knowledge, we enable them to make informed decisions.",
        },
      ]}
      reverse
    />
  )
}

export default CompanyValues
